import React from "react"
import { Link, graphql } from "gatsby"
import { Box, Heading, Grid, Paragraph, ResponsiveContext } from "grommet"
import Img from "gatsby-image"
import Seo from "../components/seo"
import Layout from "../components/layout"
import SignUp from "../components/signup"
import styled from "styled-components"
import theme from "../themes/po3Theme.json"

const JournalLink = styled(Link)`
  color: ${theme.global.colors.text.light};
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`
export default function Contact({ data }) {
  const comingsoon = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  return (
    <Layout>
      <Seo title="Book by Po3" />
      <Box>
        <Box
          width="xlarge"
          alignSelf="center"
          align="start"
          pad={{ horizontal: "medium" }}
          margin={{ bottom: "xlarge" }}
        >
          <Box direction="row-responsive" gap="large">
            <Box responsive={false} basis="1/3" round="xsmall">
              <Img fluid={data.cover.childImageSharp.fluid} />
            </Box>
            <Box basis="2/3" justify="center">
              <Heading level="3" margin="none" size="medium">
                #TheFalseEconomy
              </Heading>
              <Paragraph size="small" fill>
                We’re serialising our book, a ‘how to’ on purpose driven
                business design. Open sourcing our approach to rapid business
                design for a better economy and a sustainable future. Get all
                our IP every week, immediate access to Econsultancy’s Best
                Practice Guide to Rapid Innovation, a free copy of the book when
                finished and an invite to our Slack community.
              </Paragraph>
              <SignUp />
            </Box>
          </Box>
          <Box margin={{ top: "large", bottom: "medium" }}>
            <Heading level="4" margin="none" size="medium">
              Contents:
            </Heading>
          </Box>
          <ResponsiveContext.Consumer>
            {size => (
              <Grid
                columns={size === "small" ? "auto" : ["flex", "flex", "flex"]}
                gap={size}
              >
                {data.chapters.nodes.map((article, i) => {
                  return (
                    <Box key={i} margin={{ bottom: "medium" }}>
                      <JournalLink to={`/${article.slug}`} key={i}>
                        <Box round="xsmall" height="small">
                          <Img
                            alt={article.frontmatter.title}
                            fluid={
                              article.frontmatter.image
                                ? article.frontmatter.image.childImageSharp
                                    .fluid
                                : data.allFile.edges[0].node.childImageSharp
                                    .fluid
                            }
                            objectFit="cover"
                            style={{ height: "300px" }}
                          />
                        </Box>

                        <Heading
                          level={3}
                          size="xsmall"
                          margin={{ bottom: "none" }}
                        >
                          {article.frontmatter.title}
                        </Heading>
                      </JournalLink>
                    </Box>
                  )
                })}
                {comingsoon.map((soon, i) => {
                  return (
                    <Box key={i} margin={{ bottom: "medium" }}>
                      <Box
                        round="xsmall"
                        height="small"
                        background={{
                          color: "light-3",
                        }}
                      ></Box>
                      <Heading
                        level={3}
                        size="xsmall"
                        margin={{ bottom: "none" }}
                      >
                        Coming soon
                      </Heading>
                    </Box>
                  )
                })}
              </Grid>
            )}
          </ResponsiveContext.Consumer>
        </Box>
      </Box>
    </Layout>
  )
}
export const query = graphql`
  query Book {
    chapters: allMdx(sort: {fields: frontmatter___date, order: ASC}, filter: {frontmatter: {type: {eq: "book"}}}) {
      nodes {
        slug
        frontmatter {
          title
          author
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
    cover: file(relativePath: { eq: "static/images/transparentbook.png" }) {
      childImageSharp {
        fluid(maxWidth: 1024, quality: 100) {
          src
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
